import { delay } from 'rxjs/operators';
import { of } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { HOTELS } from "./mock-hotels";

@Injectable({
  providedIn: 'root'
})

export class HotelProvider {

    public isLoading: any;

    hotels: Array<any> = [];
    room: any;
    favoriteCounter: number = 0;
    favorites: Array<any> = [];
    bookingCounter: number = 0;
    bookings: Array<any> = [];

    constructor(private fbstore: AngularFirestore) {
        this.getHotels();
    }

    getHotels(){
        try{
            this.fbstore.collection('rooms', ref => ref.where('isPublished', '==', true)).snapshotChanges()
            .subscribe(data => {
            return data.map(result => {
                return {
                id: result.payload.doc.id,
                name: result.payload.doc.data()["name"],
                rating: result.payload.doc.data()["rating"],
                price: result.payload.doc.data()["price"],
                sale_price: result.payload.doc.data()["sale_price"],
                location: result.payload.doc.data()["location"],
                address: result.payload.doc.data()["address"],
                description: result.payload.doc.data()["description"],
                location_text: result.payload.doc.data()["location_text"],
                features: result.payload.doc.data()["features"],
                room_amenities: result.payload.doc.data()["room_amenities"],
                thumb: result.payload.doc.data()["thumb"],
                images: result.payload.doc.data()["images"],
                services: result.payload.doc.data()["services"],
                }
            });
        });
        }catch(error){
            // console.log("Error: ", error.message);
        }
    }

    getAll() {
        return this.hotels;
    }

    getItem(id: string) {
        for (var i = 0; i < this.hotels.length; i++) {
            if (this.hotels[i].id === parseInt(id)) {
                return this.hotels[i];
            }
        }
    }

    getRoom(hotelID: string, roomID: string) {
        let hotel = this.getItem(hotelID);

        for (let i = 0; i < hotel.rooms.length; i++) {
            if (hotel.rooms[i].id === parseInt(roomID)) {
                return hotel.rooms[i];
            }
        }
    }

    remove(item: any) {
        this.hotels.splice(this.hotels.indexOf(item), 1);
    }

    /////
    //For Search and Favorites
    ////
    findAll() {
        return Promise.resolve(this.hotels);
    }

    findById(id) {
        return Promise.resolve(this.hotels[id - 1]);
    }

    findByName(searchKey: string) {
        let key: string = searchKey.toUpperCase();
        return Promise.resolve(this.hotels.filter((property: any) =>
            (property.title + ' ' + property.address + ' ' + property.city + ' ' + property.description).toUpperCase().indexOf(key) > -1));
    }

    getFavorites() {
        return Promise.resolve(this.favorites);
    }

    getBookings() {
        return Promise.resolve(this.bookings);
    }

    favorite(hotel) {
        this.favoriteCounter = this.favoriteCounter + 1;
        let exist = false;

        if (this.favorites && this.favorites.length > 0) {
            this.favorites.forEach((val, i) => {
                if (val.hotel.id === hotel.id) {
                    exist = true;
                }
            });
        }

        if (!exist) {
            this.favorites.push({ id: this.favoriteCounter, hotel: hotel });
        }

        return Promise.resolve();
    }

    booking(hotel) {
        this.bookingCounter = this.bookingCounter + 1;
        let existb = false;

        if (this.bookings && this.bookings.length > 0) {
            this.bookings.forEach((val, i) => {
                if (val.hotel.id === hotel.id) {
                    existb = true
                }
            });
        }

        if (!existb) {
            this.bookings.push({ id: this.bookingCounter, hotel: hotel });
        }

        return Promise.resolve();
    }

    unfavorite(favorite) {
        let index = this.favorites.indexOf(favorite);
        if (index > -1) {
            this.favorites.splice(index, 1);
        }
        return Promise.resolve();
    }
}