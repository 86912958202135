import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () =>  import('./pages/landing/landing.module').then( m => m.LandingPageModule) },
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule) },
  { path: 'room-list', loadChildren: () => import('./pages/hotel-list/hotel-list.module').then(m => m.HotelListPageModule) },
  { path: 'room-list/:price', loadChildren: () => import('./pages/hotel-list/hotel-list.module').then(m => m.HotelListPageModule) },
  { path: 'room-detail/:id', loadChildren: () => import('./pages/hotel-detail/hotel-detail.module').then(m => m.HotelDetailPageModule) },
  { path: 'favorites', loadChildren: () => import('./pages/favorites/favorites.module').then(m => m.FavoritesPageModule) },
  // { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) },
  // { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule) },
  // { path: 'booking-list', loadChildren: () => import('./pages/booking-list/booking-list.module').then(m => m.BookingListPageModule) },
  // { path: 'edit-profile', loadChildren: () => import('./pages/edit-profile/edit-profile.module').then(m => m.EditProfilePageModule) },
  // { path: 'settings', loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule) },
  { path: 'intro', loadChildren: () => import('./pages/walkthrough/walkthrough.module').then(m => m.WalkthroughPageModule) },
  // { path: 'room-checkout/:hotelID/:roomID', loadChildren: () => import('./pages/hotel-checkout/hotel-checkout.module').then(m => m.HotelCheckoutPageModule) },
  // { path: 'local-weather', loadChildren: () => import('./pages/local-weather/local-weather.module').then(m => m.LocalWeatherPageModule) },
  // { path: 'about', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutPageModule) },
  // { path: 'support', loadChildren: () => import('./pages/support/support.module').then(m => m.SupportPageModule) },
  // { path: 'messages', loadChildren: () => import('./pages/messages/messages.module').then(m => m.MessagesPageModule) },
  // { path: 'message/:id', loadChildren: () => import('./pages/message/message.module').then(m => m.MessagePageModule) },
  // { path: 'rentcar', loadChildren: () => import('./pages/rentcar/rentcar.module').then(m => m.RentcarPageModule) },
  // { path: 'cars-list', loadChildren: () => import('./pages/cars-list/cars-list.module').then(m => m.CarsListPageModule) },
  // { path: 'car-detail/:id', loadChildren: () => import('./pages/car-detail/car-detail.module').then(m => m.CarDetailPageModule) },
  // { path: 'car-checkout/:carshopID/:carID', loadChildren: () => import('./pages/car-checkout/car-checkout.module').then(m => m.CarCheckoutPageModule) },
  // { path: 'location', loadChildren: () => import('./pages/modal/location/location.module').then(m => m.LocationPageModule) },
  // { path: 'activities', loadChildren: () => import('./pages/activities/activities.module').then(m => m.ActivitiesPageModule) },
  // { path: 'activity-list', loadChildren: () => import('./pages/activity-list/activity-list.module').then(m => m.ActivityListPageModule) },
  // { path: 'activity-detail/:id', loadChildren: () => import('./pages/activity-detail/activity-detail.module').then(m => m.ActivityDetailPageModule) },
  // { path: 'activity-checkout/:tripID', loadChildren: () => import('./pages/activity-checkout/activity-checkout.module').then(m => m.ActivityCheckoutPageModule) },
  // { path: 'extras', loadChildren: () => import('./pages/extras/extras.module').then(m => m.ExtrasPageModule) },
  // { path: 'extras/profile-one', loadChildren: () => import('./pages/extras/profile-one/profile-one.module').then(m => m.ProfileOnePageModule) },
  // { path: 'extras/profile-two', loadChildren: () => import('./pages/extras/profile-two/profile-two.module').then(m => m.ProfileTwoPageModule) },
  // { path: 'extras/timeline', loadChildren: () => import('./pages/extras/timeline/timeline.module').then(m => m.TimelinePageModule) },
  // { path: 'extras/authentication', loadChildren: () => import('./pages/extras/authentication/authentication.module').then(m => m.AuthenticationPageModule) },
  // { path: 'extras/popupmenu', loadChildren: () => import('./pages/extras/popupmenu/popupmenu.module').then(m => m.PopupmenuPageModule) },
  // { path: 'extras/charts', loadChildren: () => import('./pages/extras/charts/charts.module').then(m => m.ChartsPageModule) },
  // { path: 'extras/post', loadChildren: () => import('./pages/extras/post/post.module').then(m => m.PostPageModule) },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
